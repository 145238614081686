(function () {'use strict';}());
angular.module('app').service('AppService', function($http)
{
	var trace = angular.trace;
	var self = {};

	self.initialize = function() {
		return $http.post("php/services/NorthwellService.php",{request:"initialize"}).success(onInitialize);
	};

	self.register = function(info) {
		return $http.post("php/services/NorthwellService.php",{request:"register",info: info}).success(onRegister);
	};

	self.login = function(info) {
		return $http.post("php/services/NorthwellService.php",{request:"login", username: info.username, password: info.password}).success(onLogin);
	}; 

	self.logout = function() {
		return $http.post("php/services/NorthwellService.php",{request:"logout"});
	};

	self.saveBasicUserInfo = function(info) {
		return $http.post("php/services/NorthwellService.php",{request:"saveBasicUserInfo", info: info});
	};

	self.saveUserAffiliation = function(affiliation) {
		return $http.post("php/services/NorthwellService.php",{request:"saveUserAffiliation", ctn: affiliation});
	};

	self.saveUserPassword = function(current, updated) {
		return $http.post("php/services/NorthwellService.php",{request:"saveUserPassword", current: current, updated: updated});
	};

	self.emailResetLink = function(email) {
		return $http.post("php/services/NorthwellService.php",{request:"emailResetLink",email: email});
	};

	self.resetPassword = function(password) {
		return $http.post("php/services/NorthwellService.php",{request:"resetPassword", password: password});
	};

	self.generateReport = function() {
		return $http.post("php/services/NorthwellService.php",{request:"generateReport"});
	};

	self.generateAssessmentReport = function() {
		return $http.post("php/services/NorthwellService.php",{request:"generateAssessmentReport"});
	};

	function onInitialize(result) {
		self.modules = result.modules;
		self.userInfo = result.userInfo;
		self.resources = result.resources;
	}


	function onRegister(result) {
		self.modules = result.modules;
		self.userInfo = result.userInfo;
		return result;
	}

	function onLogin(result) {
		self.modules = result.modules;
		self.userInfo = result.userInfo;
		return result;
	}
	return self;
});