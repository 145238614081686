(function () {'use strict';}());

angular.module('app')
  .controller('AppController', function ($scope,$timeout,$window,AppService) {
  	var trace = angular.trace;

    $scope.PAGE_LOGIN = 1;
    $scope.PAGE_REGISTRATION = 2;
    $scope.PAGE_LESSONS = 3;
    $scope.PAGE_RESOURCES = 4;
    $scope.PAGE_ADMINISTRATOR = 5;
    $scope.PAGE_ABOUT = 6;
    $scope.PAGE_USING = 7;
    $scope.PAGE_CONTINUING = 8;
    $scope.PAGE_ACCOUNT = 9;
    $scope.PAGE_PASSWORD = 10;
		$scope.PAGE_WELCOME = 11;
		/** NEXT PAGE INSERTION MARKER INDEX: 12 **/
  	/** NOTE DO NOT CHANGE LINE ABOVE MANUALLY **/

    $scope.currentPage = null;
    $scope.user = null;
    $scope.transitioning = false;
    $scope.calling = null;

    AppService.initialize().then(initialize);

    $scope.setCurrentPage = function(value) {
      if($scope.transitioning === true) return;
      $scope.transitioning = true;
      $scope.calling = value;
      $timeout(transition,250);
    };

    function transition() {
      $scope.currentPage = $scope.calling;
      $timeout(updatePage,0);
    }

    function updatePage() {
      $scope.transitioning = false;
      $scope.calling = null;
    }

  	$scope.getPageClassName = function(value) {
      for(var prop in $scope) {
        if(!prop.match(/PAGE_/gim)) continue;
        if($scope[prop] !== value) continue;
        return prop.toLowerCase().replace(/page_/gim,"");
      }
      return null;
    };

    $scope.launchModule = function(id) {
      window.open("modules/northwell-module-"+id,"_blank");
    };

    $scope.setCurrentUser = function(user) {
      $scope.user = user;
      $scope.modules = AppService.modules;
    };

    $scope.register = function(userInfo) {
      return AppService.register(userInfo);
    };

    $scope.login = function(userInfo) {
      return AppService.login(userInfo);
    };

    $scope.logout = function() {
      return AppService.logout().then(onLogout);
    };

    function onLogout() {
      $window.location.reload();
    }

    function initialize() {
      if(angular.GET.debug === "true" && angular.GET.page) {
        $scope.setCurrentPage($scope["PAGE_"+angular.GET.page.toUpperCase()]);
      } else if(angular.GET.reset === "true" && angular.GET.page === "password" && __EXPIRED_LINK__ === false) {
        $scope.setCurrentPage($scope.PAGE_PASSWORD);
      } else {
         $scope.setCurrentPage(__AUTHENTICATED__ ? $scope.PAGE_WELCOME : $scope.PAGE_LOGIN);
      }

      $scope.user = AppService.userInfo;
      $scope.modules = AppService.modules;
      $scope.resources = AppService.resources;
     
    }

  });
